import { gql } from '@apollo/client/core';

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      iconName
      link
      category
      translationData {
        key
        value
      }
      translationKey
    }
  }
`;

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const DEFAULT_CREDENTIALS = 'same-origin';

export const cache = new InMemoryCache({
  typePolicies: {
    Landlord: {
      fields: {
        stripe: {
          merge: true
        }
      }
    },
    Property: {
      fields: {
        location: {
          merge: true
        }
      }
    }
  }
});

const createApolloClient = (
  uri = '/landlord-properties/graphql',
  credentials = DEFAULT_CREDENTIALS
) =>
  new ApolloClient({
    link: new HttpLink({ uri, credentials }),
    cache,
    assumeImmutableResults: false
  });

export default createApolloClient;
